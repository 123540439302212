<template>
	<div>
		<OrganismsHeaderCheckout :sourcePublication="sourcePublication" />
		<main class="u-main-content u-main-content--checkout" v-if="isArchived">
			<p class="mt-10 fs-6 text-center">Dieses Angebot ist nicht mehr erhältlich</p>
			<OrganismsFooterCheckout
				:contactMail="contactMail"
				:contactPhone="contactPhone"
				class="u-checkout__section--max-content-width"
			/>
		</main>
		<main class="u-main-content u-main-content--checkout" v-else>
			<OrganismsProductCheckout
				:sourcePublication="sourcePublication"
				:paywallOfferObject="offerData"
				v-if="offerData && activeState < 3"
				ref="offerObjectRef"
				class="u-checkout__section--max-content-width"
			/>
			<slot></slot>
			<OrganismsUpdateOfferCheckout
				:sourcePublication="sourcePublication"
				:updateOfferObject="updateOfferData"
				v-if="updateOfferData && showUpdateOffer && activeState > 0"
				class="u-checkout__section--max-content-width"
				@PromotionButtonClicked="() => useTrackPromotionOfferClick(updateOfferData)"
			/>
			<OrganismsFooterCheckout
				:contactMail="contactMail"
				:contactPhone="contactPhone"
				class="u-checkout__section--max-content-width"
			/>
		</main>
		<CheckoutFooter class="u-main-content"></CheckoutFooter>
	</div>
</template>

<script lang="ts" setup>
	import { useOfferStore } from "~/stores/offer.store";
	import { DwhTracking } from "~/tracking/track.dwh";
	import { useRouteQuery } from "@vueuse/router";
	const offerStore = useOfferStore();
	const tracking = new DwhTracking(usePublication());
	const config = useClientSideConfig();
	const contactMail = config.contactMail;
	const contactPhone = config.contactPhone;
	const sourcePublication = usePublication();
	const showUpdateOffer = ref(false);
	let stepTrackingHasStarted = ref(false);

	const offerId = useRouteQuery("offerid");
	if (!offerId.value) {
		offerId.value = tryDifferentCaseForOfferParam(useRoute());
	}
	const offerData: Ref<any> = ref(false);
	const updateOfferData: Ref<any> = ref(false);
	const isArchived: Ref<boolean> = ref(false);

	if (offerId.value) {
		const { data } = await useFetch(`/api/checkout/offer/${offerId.value}`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
		});

		const offerDataResponse = data.value?.additionalData;

		isArchived.value = offerDataResponse?.isArchived;

		offerData.value = {
			productTitle: offerDataResponse?.title,
			productTeaser: offerDataResponse?.teaser,
			productStep1Price: offerDataResponse?.price_1,
			productStep1Text: offerDataResponse?.duration_1,
			productStep1BillingCycle: offerDataResponse?.billing_cycle_1,
			productStep2Price: offerDataResponse?.price_2,
			productStep2Text: offerDataResponse?.duration_2,
			productStep2BillingCycle: offerDataResponse?.billing_cycle_2,
			productStep3Price: offerDataResponse?.price_3,
			productStep3Text: offerDataResponse?.duration_3,
			productStep3BillingCycle: offerDataResponse?.billing_cycle_3,
			productInformation: offerDataResponse?.legalText,
		};

		const updateOfferDataResponse = data.value?.upsellingData;
		updateOfferData.value = {
			updateTitle: updateOfferDataResponse?.upselling_teaser,
			updateOfferId: updateOfferDataResponse?.upselling_offerid,
			updateCrossedOutPrice: updateOfferDataResponse?.upselling_save,
			updateRealPrice: updateOfferDataResponse?.upselling_price,
			updateBulletItems: updateOfferDataResponse?.upselling_bulletData,
			updateButtonText: "Jetzt upgraden",
			updateCheckoutUrl: "/checkout/",
		};
	}

	const offerObjectRef = ref(null);
	const activeState = computed(() => {
		let state = 0;
		if (import.meta.client) {
			state = useCheckoutStore().session.checkoutState;
		}
		return state || 0;
	});

	if (import.meta.client) {
		stepTrackingHasStarted = computed(() => {
			return useCheckoutStore().session.actualUrl || 0;
		});
	}

	watch(activeState, (newVal) => {
		if (newVal === 2 && offerObjectRef.value) {
			offerObjectRef.value.toggleHeader(true);
		}
		showUpdateOffer.value =
			!useCheckoutStore().session.paymentMethod && offerStore.updateOfferObject.updateOfferId && activeState.value < 3;
		if (typeof showUpdateOffer.value !== "undefined" && showUpdateOffer.value === true) {
			tracking.promotionViewed({
				type: "promo_view",
				position: 1,
				slot: "beyondCheckout",
				productIds: [offerStore?.updateOfferObject?.updateOfferId],
			});
		}
	});

	onMounted(() => {
		useCheckoutStore().session.position = 1; // Es gibt nur eine Position für das Updateangebot
	});

	/**
	 * Sucht nach dem Parameter "offerid" in beliebiger Schreibweise (Groß/klein). Gibt den zugehörigen Wert zurück, wenn er gefunden wurde
	 * @param route
	 * @return null|string
	 */
	function tryDifferentCaseForOfferParam(route): null | string {
		const routeParamsArray = Object.keys(route.query);
		const foundParamKey = routeParamsArray.find((entry) => entry.toLowerCase() === "offerid");
		//Check for keys that are not written correctly
		if (foundParamKey) {
			console.log("Param is not written correctly. We found it anyway: " + foundParamKey + ": " + route.query[foundParamKey]);
			return route.query[foundParamKey];
		}
		return null;
	}
</script>
