<template>
	<footer class="u-checkout-footer p-6">
		<ul class="u-checkout-footer__nav fs-1">
			<li v-for="item in footernav">
				<AtomsBaseLink v-bind="item" />
			</li>
		</ul>
		<div class="u-checkout-footer__logos">
			<img v-for="logo in footerLogos" :key="logo.alt" :src="logo.image" :alt="logo.alt" class="u-footer__logo" />
		</div>
	</footer>
</template>

<script lang="ts" setup>
	const menuId = useClientSideConfig().checkoutFooterMenuId;
	const { data: menu } = await useFetch(`/api/menu/${menuId}`);

	const footernav = (menu.value || []).map((item: any) => {
		return {
			url: item.url,
			label: item.label,
			external: true,
		};
	});
	const footerLogos = [
		{
			alt: "PayPal",
			image: "/static/images/paypal.svg",
		},
		{
			alt: "MasterCard",
			image: "/static/images/master-card.svg",
		},
		{
			alt: "Visa",
			image: "/static/images/visa.svg",
		},
		{
			alt: "Amex",
			image: "/static/images/amex.svg",
		},
		{
			alt: "SEPA",
			image: "/static/images/sepa.svg",
		},
	];
</script>

<style lang="scss"></style>
