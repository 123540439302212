import { DwhTracking } from "~/tracking/track.dwh";

export default (updateOfferData: Object) => {
	const tracking = new DwhTracking(usePublication());
	tracking.promotionClicked({
		type: "promo_click",
		position: 1,
		slot: "beyondCheckout",
		productIds: [updateOfferData?.updateOfferId]
	});
	tracking.productRemovedFromCart();
};